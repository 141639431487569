import React from 'react'
import './implementation2.css'

const Implementation = () => {
  return (
    <section id='implementation'>
        <div className='container implementation_main'>
             <h2>Polygon CDK Implementation</h2>
               <div className='col-lg-6 col-md-8 col-sm-9 implementation-content'>

                Ethernal is Polygon Chain Development Kit (CDK) implementation provider, enabling you to build your own ZK-powered Layer 2, custom-fitted to your needs.
               
                </div>
               

             {/* <div className='col-lg-3 col-md-3 col-sm-3 implementation-btn'>
             <p><a href="https://polygon.technology/polygon-cdk" target="_blank" rel="noopener noreferrer" className='no-wrap'>Learn about CDK</a></p>
             </div> */}

              <a href="https://polygon.technology/polygon-cdk" target="_blank" rel="noopener noreferrer" className='implementation-btn-new no-wrap'>
                          <span>Learn about CDK</span>
                         </a>
            

        </div>
    </section>
  )
}

export default Implementation