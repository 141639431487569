import React from 'react';
import './projects3.css';

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

import projectBlade from "../../assets/images/projects/blade-blue-projects.svg"
 import projectEdge from "../../assets/images/projects/PolygonEdge340.svg"
import projectAvail from "../../assets/images/projects/avail-brand2.svg"
import projectGravity from "../../assets/images/projects/GravityBridge340.svg"
import projectInterchain from "../../assets/images/projects/Interchain340.svg"
import projectCosmosHub from "../../assets/images/projects/CosmosHub340.svg"
 import projectApexFusion from "../../assets/images/projects/apexfusion-projects.svg"

const Projects2 = () => {

 

    const options ={
    
     items:1,
      center:true,
      dots:false,
      nav: true,
     navText:[ "", ""],
      loop:true,
        margin: 10,
        responsiveClass:true,
    responsive:{
        0:{
            items:1
           
        },
        600:{
            items:1
            
            
        },
        1000:{
            items:1
           
            
        }
    }

  }
  return (
   <section id="projects">
        <div className='container projects2-main'>
          <div className='row projects2-content1'>
            <div className='col-lg-12 col-md-12 col-md-12 projects2-text'>
                <h2 className='projects2-title'>Projects</h2>
            </div>
          </div>


          {/* <div className='row  projects2-content2'>
            <div className='col-lg-9 col-md-9 col-md-12 y'> */}

               <div className='projects2-owl-wrapper'>
                <OwlCarousel className='owl-theme' {...options}>
      
                   {/* project blade  */}
            <div className="row project2 item">
              
              <div className="col-lg-6 col-md-6 col-md-12 project2-text">
                 
                <h4 className='projects2-subtitle2'>Blade Technology</h4>
                <p className='p12'>
                 Ethernal is developing Ethereum node software engineered for high performance.
                </p>
                
             <p className='p22'><span><a href="https://bladeblockchain.tech/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></p>
            
              </div>
              <div className="col-lg-4 col-md-4 col-md-12 project2-img ">
                <img src={projectBlade} alt="BladeTechnology"  />
                {/* <p className='projects2-polygon-text no-wrap'>Polygon <span>Edge</span></p> */}
              </div>

                
            </div>
               

               {/* project 1  */}
            <div className="row project2 item">
              
              <div className="col-lg-6 col-md-6 col-md-12 project2-text">
                 
                <h4 className='projects2-subtitle2'>Polygon Edge</h4>
                <p className='p12'>
                 Ethernal collaborates with Polygon on the development of Edge, the modular and extensible framework for building private or public Ethereum-compatible blockchain networks.
                </p>
                
             <p className='p22'><span><a href="https://github.com/0xPolygon/polygon-edge" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></p>
            
              </div>
              <div className="col-lg-4 col-md-4 col-md-12 project2-img ">
                <img src={projectEdge} alt="PolygonEdge"  />
                {/* <p className='projects2-polygon-text no-wrap'>Polygon <span>Edge</span></p> */}
              </div>

                
            </div>
        
                {/* project 2 */}
            <div className="row project2 item">
             
              <div className="col-lg-6 col-md-6 col-md-12 project2-text">
                
                <h4 className='projects2-subtitle1'>Avail</h4>
               
                <p className='p12'>Ethernal collaborates with Avail on the development of highly scalable data availability layer designed to power sovereign rollups, sovereign chains, Validiums and optimistic chains.</p>
                
               
             <p className='p22'><span><a href="https://www.availproject.org/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></p>
           
              </div>
               <div className="col-lg-4 col-md-4 col-md-12 project2-img " >
                 <img className='avail-logo' src={projectAvail} alt="Avail"  />
                {/* <p className='projects-polygon-text no-wrap'>Polygon <span>Avail</span></p> */}
              </div>

            </div>
            
              {/* project 3  */}
            <div className="row project2 item">
              
              <div className="col-lg-6 col-md-6 col-md-12 project2-text">
                 
                <h4 className='projects2-subtitle2'>Gravity Bridge</h4>
                <p className='p12'>
                 Ethernal collaborates with Informal Systems on the development of Gravity Bridge, the fully decentralized, trustless blockchain which bridges assets between the Ethereum and Cosmos ecosystems.
                </p>
                
             <p className='p22'><span><a href="https://www.gravitybridge.net/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></p>
            
              </div>
              <div className="col-lg-4 col-md-4 col-md-12 project2-img ">
                <img src={projectGravity} alt="GravityBridge"  />
                
              </div>


            </div>

              {/* project 4  */}
            <div className="row project2 item">
              
              <div className="col-lg-6 col-md-6 col-md-12 project2-text">
                 
                <h4 className='projects2-subtitle2'>Interchain security</h4>
                <p className='p12'>
                 Ethernal collaborates with Informal Systems on the development of Interchain Security, the feature which allows the Cosmos Hub's validators to secure other blockchains.
                </p>
                
             <p className='p22'><span><a href="https://interchainsecurity.dev/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></p>
            
              </div>
              <div className="col-lg-4 col-md-4 col-md-12 project2-img ">
                <img src={projectInterchain} alt="InterchainSecurity"  />
                 
              </div>


            </div>

             {/* project 5  */}
            <div className="row project2 item">
              
              <div className="col-lg-6 col-md-6 col-md-12 project2-text">
                 
                <h4 className='projects2-subtitle2'>Cosmos Hub</h4>
                <p className='p12'>
                 Ethernal collaborates with Informal Systems on the development of Cosmos Hub, the first public proof-of-stake blockchain built on top of a Byzantine Fault Tolerant consensus engine.
                </p>
                
             <p className='p22'><span><a href="https://cosmos.network/features" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></p>
            
              </div>
              <div className="col-lg-4 col-md-4 col-md-12 project2-img ">
                <img src={projectCosmosHub} alt="CosmosHub"  />
                 
              </div>


            </div>

            {/* project 6 apex test */}
             <div className="row project2 item">
              
              <div className="col-lg-6 col-md-6 col-md-12 project2-text">
                 
                <h4 className='projects2-subtitle2'>Apex Fusion</h4>
                <p className='p12'>
                 Ethernal is developing a bridge that seamlessly connects UTXO and accounts-based blockchains, such as Cardano and Polygon. The primary objective of this undertaking is to unlock innovative opportunities for dApps while empowering the concurrent utilization of multiple blockchain capabilities.
                </p>
                
             <p className='p22'><span><a href="https://www.apexfusion.org/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></p>
            
              </div>
              <div className="col-lg-4 col-md-4 col-md-12 project2-img ">
                <img src={projectApexFusion} alt="ApexFusion"  />
                 
              </div>


            </div>
            
             </OwlCarousel>

               </div>
              
            {/* </div>
          </div> */}

        </div>
        
        </section>
  )
}

export default Projects2
